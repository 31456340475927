import '@css/main.scss';
import CryptoJS from 'crypto-js';

import {Header} from './utils/Header'
import {Tablist} from "./utils/Tablist";
import {MouseMovingElement} from "./utils/MouseMovingElement";
import {Slider} from "./utils/Slider";
import {Tabbuttons} from "./utils/Tabbuttons"
import {titleAnim} from "./utils/titleAnim";
import {scrollAnim} from "./utils/scrollAnim";

window.onload = () => {

  new titleAnim()
  new scrollAnim()
  new Header()
  new MouseMovingElement()
  new Slider()
  new Tabbuttons()

  setTimeout(() => {
    new Tablist()
  }, 250)


  // const toBase64UrlSafe = (base64) => {
  //   return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  // };
  // function prepareTransaction()
  // {
  //
  //   console.log('prepare transaction')
  //
  //   const orderID = "panier-101";
  //   const paymentID = "100";
  //   const amount = 3000;
  //
  //   const messageToHash = "90005372&544148" + '&' + orderID + "&" + paymentID + "&" + amount;
  //   const hmac = CryptoJS.HmacSHA256(messageToHash, "a195F6dN+zECmhwIXx9W1EAJjE4yev/H/IeVKs5QHpZpq0ZZvALl+wcNyCYR/FiREsTzDdQWhcIh0E/2Hsy1mlal");
  //   const hmacBase64 = hmac.toString(CryptoJS.enc.Base64);
  //   const hmacBase64UrlSafe = toBase64UrlSafe(hmacBase64);
  //
  //   // jYH_mch6tIlKN0Rr_75Tm2V2q8OnQQjc-f36H8_xG5w
  //   console.log(hmacBase64UrlSafe)
  //
  //   // @ts-ignore
  //   fetch('https://recette.connect.ancv.com/acquisition/api/public/v1/payment-transactions', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Ancv-Security': 'HmacSHA256.ver001.jYH_mch6tIlKN0Rr_75Tm2V2q8OnQQjc-f36H8_xG5w',
  //     },
  //     body: JSON.stringify({
  //       merchant: {
  //         shopId: 90005372,
  //         serviceProdiverId: 544148
  //       },
  //       order: {
  //         id: "panier-101",
  //         paymentId: "100",
  //         amount: {
  //           total: 3000,
  //           currency: "978"
  //         }
  //       },
  //       paymentMethod: {
  //         captureMode: 'NORMAL',
  //         tspdMode: '001'
  //       },
  //     })
  //   })
  //     .then(response => {
  //       // Handle response
  //       console.log(response)
  //     })
  //     .catch(error => {
  //       // Handle error
  //       console.error(error)
  //     });
  // }
  //
  // prepareTransaction()

  const myHeaders = new Headers();
  myHeaders.append("Access-Control-Allow-Origin", "recette.connect.ancv.com");
  myHeaders.append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
  myHeaders.append("Access-Control-Allow-Headers", "Content-Type, Ancv-Security, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Ancv-Security", "HmacSHA256.ver001.jYH_mch6tIlKN0Rr_75Tm2V2q8OnQQjc-f36H8_xG5w");

  const raw = JSON.stringify({
    "merchant": {
      "shopId": 90005372,
      "serviceProviderId": 544148
    },
    "order": {
      "id": "panier-101",
      "paymentId": "100",
      "amount": {
        "total": 3000,
        "currency": "978"
      }
    },
    "paymentMethod": {
      "captureMode": "NORMAL",
      "tspdMode": "001"
    }
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://recette.connect.ancv.com/acquisition/api/public/v1/payment-transactions", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));


}

