export class Header {
    constructor()
    {
        this.burger()
        this.scrollHandler()
    }

    burger()
    {
        this.header = document.querySelector('body header')
        if (this.header) {
            this.nav = document.querySelector('.header-nav__burger')
            const burger = document.querySelector('#burger')
            const menu = document.querySelector('.header-nav__nav')

            if (burger) {

                burger.addEventListener('click', evt => {
                    evt.preventDefault()
                    burger.classList.toggle('active')
                    this.nav.classList.toggle('expanded')
                })
                window.addEventListener('resize', evt => {

                      let breakpoint = burger.getAttribute('data-breakpoint')
                    if (window.matchMedia("(max-width: " + breakpoint + "px)").matches) {
                        menu.style.display = "none";
                        burger.classList.remove('hidden');
                        burger.classList.add('show');
                    } else {
                        menu.style.display = "block";
                        burger.classList.remove('show');
                        burger.classList.add('hidden');
                    }

                  })
            }

            const burgerSubmenus = document.querySelectorAll(".menu-item-has-children")

            burgerSubmenus.forEach(t => {

                let trigger = t.querySelectorAll('a')[0].offsetHeight + 10
                let height = t.querySelector('.sub-menu').offsetHeight + trigger + 15

                t.style.setProperty('--max-height', height + 'px')
                t.style.setProperty('--init-height', trigger + 'px')

                t.addEventListener('click', e => {
                    let submenus = document.querySelectorAll(".menu-item-has-children")
                    submenus.forEach(s => {
                        s.classList.remove('open')
                    })
                t.classList.toggle('open')
                })
            })
        }
    }

    scrollHandler()
    {

        if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
            this.header.classList.add('scrolled')
        }

        document.addEventListener('scroll', () => {
            let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

            if (height > 0 && !this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
            if (height === 0 && this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
        })

    }

}
